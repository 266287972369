<template>
  <div v-if="error">{{ error }}</div>
  <div v-if="recipe">
    <Card>
      <template #title>
        {{ recipe.title }}
      </template>
      <template #content>
        <div class="p-fluid  p-formgrid p-grid">
          <div class="p-field p-col-12">
            <RecipeEditUpload
              :recipe="recipe"
              :editMode="!disableEdit"
              :disabled="disableEdit"
              @imagesUploaded="imagesAdded"
              @imageDeleted="deleteImage"
            ></RecipeEditUpload>
          </div>
        </div>

        <div class="p-fluid  p-formgrid p-grid">
          <div class="p-field p-col-10">
            <span class="p-float-label">
              <InputText
                id="title"
                type="text"
                v-model="recipe.title"
                :disabled="disableEdit"
              />
              <label for="title">Title</label>
            </span>
          </div>

          <div class="p-field p-col-2" id="favorite-button-container">
            <ToggleButton
              v-model="recipe['is-favorite']"
              onIcon="pi pi-heart"
              offIcon="pi pi-heart"
              :disabled="disableEdit"
            />
          </div>

          <div class="p-field p-col-12 p-md-6">
            <label for="servings">Servings: {{ recipe.servings }}</label>
            <Slider
              v-model="recipe.servings"
              :min="1"
              :max="12"
              :disabled="disableEdit"
            />
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputMask
                v-model="recipe.time"
                mask="99:99"
                slotChar="hh:mm"
                id="time-to-cook"
                :disabled="disableEdit"
              />
              <label for="time-to-cook">Time to cook</label>
            </span>
          </div>

          <div class="p-field p-col-12 tag-container" v-if="disableEdit">
            <Tag
              v-for="tag in recipe.tags"
              :key="tag"
              class="p-mr-2"
              :value="tag"
              severity="info"
              rounded
            >
            </Tag>
          </div>

          <div class="p-field p-col-12 tag-container" v-else>
            <RecipeEditTags
              @updateTags="updateTags"
              :existing-tags="recipe.tags"
            ></RecipeEditTags>
          </div>

          <div class="p-field p-col-12">
            <span class="p-float-label">
              <Textarea
                v-model="recipe['additional-info']"
                :autoResize="true"
                rows="2"
                cols="30"
                id="additional-info"
                :disabled="disableEdit"
              />
              <label for="additional-info">Additional Info</label>
            </span>
          </div>

          <div class="p-field p-col-12 section-end">
            <span class="p-float-label">
              <InputText
                id="source"
                type="text"
                v-model="recipe.source"
                :disabled="disableEdit"
              />
              <label for="source">Source</label>
            </span>
          </div>

          <RecipeEditIngredients
            :existingIngredients="recipe.ingredients"
            :disableEdit="disableEdit"
            @updateIngredients="updateIngredients"
          ></RecipeEditIngredients>

          <div class="p-field p-col-12">
            <span class="p-float-label">
              <Textarea
                v-model="stepsRaw"
                :autoResize="true"
                rows="5"
                cols="30"
                id="steps"
                :disabled="disableEdit"
              />
              <label for="steps">Steps (empty line for new section)</label>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <Button
          v-if="disableEdit"
          @click="cook"
          icon="pi pi-book"
          label="Cook"
        ></Button>

        <Button
          v-if="disableEdit"
          @click="activateEdit"
          label="Edit"
          icon="pi pi-pencil"
          class="p-button-outlined"
          id="edit-button"
        ></Button>

        <Button
          v-if="!disableEdit"
          @click="saveChanges"
          label="Save"
          icon="pi pi-save"
          id="save-button"
        ></Button>

        <Button
          v-if="!disableEdit"
          @click="cancel"
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          id="cancel-button"
        ></Button>

        <Button
          v-if="!disableEdit"
          @click="deleteRecipe($event)"
          label="Delete"
          icon="pi pi-trash"
          class="p-button-danger p-button-outlined"
          id="delete-button"
        ></Button>
        <ConfirmPopup></ConfirmPopup>
      </template>
    </Card>
    <Toast></Toast>
  </div>
  <RecipeSkeleton v-else></RecipeSkeleton>
</template>

<script>
import getRecipe from "@/composables/getRecipe";
import { ref } from "@vue/reactivity";
import RecipeEditUpload from "@/components/RecipeEditUpload";
import { useConfirm } from "primevue/useconfirm";
import { projectFirestore } from "@/firebase/config";
import router from "@/router";
import { useRoute } from "vue-router";
import RecipeEditTags from "@/components/RecipeEditTags";
import { watch } from "@vue/runtime-core";
import RecipeEditIngredients from "@/components/RecipeEditIngredients";
import RecipeSkeleton from "@/components/RecipeSkeleton";

export default {
  name: "RecipeDetails",
  components: {
    RecipeSkeleton,
    RecipeEditIngredients,
    RecipeEditTags,
    RecipeEditUpload
  },
  params: ["id"],
  setup() {
    const route = useRoute();
    const { recipe, error, load } = getRecipe(route.params.id);
    load();

    const cook = () => {
      router.push({ name: "Cook", params: { id: route.params.id } });
    };

    const updateTags = newTags => {
      recipe.value.tags = newTags;
    };

    let stepsRaw = ref([]);
    watch(recipe, () => {
      stepsRaw.value = recipe.value.steps.join("\r\n\r\n");
    });

    const imagesAdded = newImages => {
      recipe.value.images = [...recipe.value.images, ...newImages];
      recipe.value.images = [...new Set(recipe.value.images)];
    };

    const deleteImage = image => {
      let index = recipe.value.images.indexOf(image);
      if (index > -1) {
        recipe.value.images.splice(index, 1);
      } else {
        console.log("NO IMAGE TO DELETE FOUND...");
      }
    };

    const updateIngredients = newIngredients => {
      recipe.value.ingredients = newIngredients;
    };

    let disableEdit = ref(true);
    const activateEdit = () => {
      disableEdit.value = false;
    };

    const saveChanges = () => {
      recipe.value.steps = stepsRaw.value.split(/\n\s*\n/);
      recipe.value.steps = recipe.value.steps.map(step =>
        step.replace(/[\n\r]/g, "")
      );
      projectFirestore
        .collection("Recipes")
        .doc(route.params.id)
        .update(recipe.value);
      disableEdit.value = true;
    };

    const cancel = () => {
      router.go();
    };

    const confirm = useConfirm();
    const deleteRecipe = event => {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this recipe?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          projectFirestore
            .collection("Recipes")
            .doc(recipe.value.id)
            .delete();
          router.push({ name: "Home" });
        },
        reject: () => {}
      });
    };

    return {
      recipe,
      error,
      stepsRaw,
      cook,
      deleteRecipe,
      disableEdit,
      activateEdit,
      saveChanges,
      imagesAdded,
      deleteImage,
      cancel,
      updateTags,
      updateIngredients
    };
  }
};
</script>

<style scoped>
.p-card {
  margin: auto;
  max-width: 50rem;
}

.p-field {
  margin-top: 1rem;
}

.section-end {
  margin-bottom: 3rem;
}

#edit-button {
  margin-left: 0.5rem;
}

#delete-button,
#cancel-button {
  margin-left: 0.5rem;
}

#favorite-button-container {
  display: flex;
  align-items: center;
}

.tag-container {
  margin-top: 0 !important;
}

.p-tag {
  margin-top: 1rem;
}
</style>
