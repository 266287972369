<template>
  <FileUpload
    name="file"
    :auto="true"
    :showCancelButton="false"
    :showUploadButton="false"
    url="https://api.cloudinary.com/v1_1/risotto/upload/"
    @select="setNames"
    @before-send="setHeaders"
    @upload="imagesUploaded"
    chooseLabel="Add"
    :class="{ 'hide-add-button': !editMode }"
  >
    <template #empty>
      <div
        v-for="recipeImage in recipe.images"
        :key="recipeImage"
        class="recipe-image-container"
      >
        <img :src="recipeImage" class="existing-images" alt="delete image" />
        <Button
          v-if="editMode"
          @click="deleteImage(recipeImage)"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger p-button-outlined delete-image-button"
        ></Button>
      </div>
    </template>
  </FileUpload>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  name: "RecipeEditUpload",
  emits: ["imagesUploaded", "imageDeleted"],
  props: {
    recipe: Object,
    editMode: Boolean
  },
  setup(props, { emit }) {
    let imageURLs = ref([]);

    const setNames = event => {
      for (let file of event.files) {
        let fileName =
          Math.random()
            .toString(36)
            .substring(2, 12) + file.name;

        Object.defineProperty(file, "name", {
          writable: true,
          value: fileName
        });
      }
    };

    const setHeaders = ev => {
      ev.formData.append("upload_preset", "default");
    };

    const imagesUploaded = ev => {
      imageURLs.value.push(JSON.parse(ev.xhr.response).secure_url);
      imageURLs.value = [...new Set(imageURLs.value)];
      emit("imagesUploaded", imageURLs.value);
    };

    const deleteImage = image => {
      emit("imageDeleted", image);
    };

    return {
      imagesUploaded,
      setNames,
      setHeaders,
      deleteImage
    };
  }
};
</script>

<style>
.p-fileupload-empty {
  display: flex;
  flex-wrap: wrap;
}
.recipe-image-container {
  display: flex;
  align-items: center;
  width: 40%;
  min-width: 20rem;
}

.existing-images {
  width: calc(100% - 4.357rem);
  margin: 1rem;
}

.delete-image-button {
  width: 2.357rem !important;
}

.hide-add-button .p-fileupload-buttonbar {
  display: none !important;
}
</style>
