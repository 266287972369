<template>
  <FileUpload
    name="file"
    :auto="true"
    :showCancelButton="false"
    :showUploadButton="false"
    url="https://api.cloudinary.com/v1_1/risotto/upload/"
    @select="setNames"
    @before-send="setHeaders"
    @upload="imagesUploaded"
    chooseLabel="Add"
  >
    <template #empty>
      <div
        v-for="recipeImage in images"
        :key="recipeImage"
        class="recipe-image-container"
      >
        <img :src="recipeImage" class="existing-images" alt="delete image" />
        <Button
          @click="deleteImage(recipeImage)"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger p-button-outlined delete-image-button"
        ></Button>
      </div>
    </template>
  </FileUpload>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  name: "RecipeCreateUpload",
  props: {
    images: Array
  },
  emits: ["imagesAdded", "imagesUploaded", "imageDeleted"],
  setup(props, { emit }) {
    let imageURLs = ref([]);

    const setNames = event => {
      for (let file of event.files) {
        let fileName =
          Math.random()
            .toString(36)
            .substring(2, 12) + file.name;

        Object.defineProperty(file, "name", {
          writable: true,
          value: fileName
        });
      }
    };

    const setHeaders = ev => {
      ev.formData.append("upload_preset", "default");
    };

    const imagesUploaded = ev => {
      imageURLs.value.push(JSON.parse(ev.xhr.response).secure_url);
      imageURLs.value = [...new Set(imageURLs.value)];
      emit("imagesUploaded", imageURLs.value);
    };

    const deleteImage = image => {
      emit("imageDeleted", image);
    };

    return {
      imagesUploaded,
      setNames,
      setHeaders,
      deleteImage
    };
  }
};
</script>

<style scoped></style>
