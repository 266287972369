import { ref } from "@vue/reactivity";
import { projectFirestore } from "@/firebase/config";

const getPosts = () => {
  const recipes = ref([]);
  const error = ref();

  const load = async () => {
    try {
      const res = await projectFirestore.collection("Recipes").get();
      recipes.value = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  return { recipes: recipes, error, load };
};

export default getPosts;
