<template>
  <TabMenu :model="menuItems" />
  <ScrollTop :threshold="200" />
  <router-view />
</template>

<script>
export default {
  setup() {
    let menuItems = [
      {label: 'Home', icon: 'pi pi-fw pi-home', to: '/'},
      {label: 'Planner', icon: 'pi pi-fw pi-calendar', to: '/meal-planner'},
      {label: 'Add', icon: 'pi pi-fw pi-plus', to: '/create/recipe'}
    ]
    return {menuItems}
  }
}
</script>

<style>
* {
}
html {
  overflow-x: hidden;
}

body {
  background-color: var(--surface-b);
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.p-tabmenu {
  margin-bottom: 3rem;
}

.p-progress-spinner {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.p-disabled {
  opacity: 0.6 !important;
}
.p-component:disabled {
  opacity: 0.6 !important;
}
</style>
