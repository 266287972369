<template>
  <div class="p-field p-col-12">
    <span class="p-float-label">
      <MultiSelect
        v-model="selectedTags"
        :options="tags"
        optionLabel="name"
        display="chip"
        id="tags"
        :filter="true"
      ></MultiSelect>
      <label for="tags">Select Tags</label>
    </span>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import getTags from "@/composables/getTags";

export default {
  name: "RecipeCreateTags",
  emits: ["updateTags"],
  setup(props, { emit }) {
    let selectedTags = ref(null);
    let tags = getTags;

    watch(selectedTags, () => {
      let tagsToEmit = [];
      for (let tag in selectedTags.value) {
        tagsToEmit.push(selectedTags.value[tag].name);
      }
      emit("updateTags", tagsToEmit);
    });

    return {
      selectedTags,
      tags
    };
  }
};
</script>

<style scoped></style>
