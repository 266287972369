<template>
  <div v-if="recipe">
    <swiper :slides-per-view="1" :space-between="50">
      <swiper-slide>
        <Card>
          <template #title>
            {{ recipe.title }}
          </template>
          <template #subtitle>
            Ingredients
          </template>
          <template #content>
            <label for="servings" style="display: block">Servings</label>
            <InputNumber
              id="servings"
              v-model="recipeServingsToShow"
              :min="0"
              showButtons
              buttonLayout="horizontal"
              style="width:4rem"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
            <p
              v-for="ingredient in ingredientsToShow"
              :key="ingredient"
              @click="ingredient.show = !ingredient.show"
              :class="{
                hide: !ingredient.show,
                'ingredient-heading': ingredient.ingredient.slice(-1) === ':'
              }"
              class="ingredient"
            >
              {{ ingredient.ingredient }}
            </p>

            <div v-if="timeToCook">
              <h3 class="p-mt-6">Time To Cook</h3>
              <p>{{ timeToCook }}</p>
            </div>

            <div v-if="additionalInfo">
              <h3 class="p-mt-6">Additional Notes</h3>
              <p>{{ additionalInfo }}</p>
            </div>

          </template>
        </Card>
      </swiper-slide>
      <swiper-slide>
        <Card>
          <template #title>
            {{ recipe.title }}
          </template>
          <template #subtitle>
            Steps
          </template>
          <template #content>
            <p
              v-for="step in stepsToShow"
              :key="step"
              @click="step.show = !step.show"
              :class="{
                hide: !step.show,
                'step-heading': step.step.slice(-1) === ':'
              }"
              class="step"
              v-html="step.step"
            ></p>
          </template>
        </Card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import getRecipe from "@/composables/getRecipe";
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { watch } from "@vue/runtime-core";

export default {
  name: "Cook",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const route = useRoute();
    let originalServings = 0;
    let recipeServingsToShow = ref(0);
    const { recipe, error, load } = getRecipe(route.params.id);
    load().then(() => {
      originalServings = recipe.value.servings;
      recipeServingsToShow.value = originalServings;
    });


    watch(recipeServingsToShow, () => {
      for (let ingredient in recipe.value.ingredients) {
        let ingredientServings =
          Math.round(
            (recipeServingsToShow.value / originalServings) *
            parseFloat(recipe.value.ingredients[ingredient].split(" ")[0]) *
            100
          ) / 100;

        let ingredientValue =
          " " + recipe.value.ingredients[ingredient].replace(/^(.*?)\s/g, "");

        let ingredientToReturn = ingredientServings + ingredientValue;

        if (recipe.value.ingredients[ingredient] === "") {
          ingredientToReturn = "";
        }
        ingredientsToShow.value[ingredient] = {
          ingredient: ingredientToReturn,
          show: true
        };
      }
    });

    let ingredientsToShow = ref([]);
    let stepsToShow = ref([]);
    let timeToCook = ref("");
    let additionalInfo = ref("");

    watch(recipe, () => {
      for (let step in recipe.value.steps) {
        stepsToShow.value[step] = {
          step: recipe.value.steps[step],
          show: true
        };
      }

      timeToCook.value = recipe.value["time"];
      additionalInfo.value = recipe.value["additional-info"];
    });

    return {
      recipe,
      error,
      ingredientsToShow,
      stepsToShow,
      recipeServingsToShow,
      timeToCook,
      additionalInfo
    };
  }
};
</script>

<style scoped>
.swiper-container {
  max-width: 95%;
}

.ingredient,
.step {
  margin: 0;
  padding: 0.7rem 0;
  font-size: 1.2rem;
}

.step {
  margin-bottom: 2rem;
}

.step:last-child {
  margin-bottom: 0;
}

.hide {
  text-decoration: line-through;
}

.ingredient-heading,
.step-heading {
  font-size: 2rem;
}
</style>

<style>
.p-inputnumber {
  margin-bottom: 1rem !important;
}

.p-inputtext.p-component.p-inputnumber-input {
  width: 3.5rem !important;
}
</style>
