<template>
  <div class="home">
    <div id="filter-toggle-container">
      <ToggleButton
        v-model="showFilterOptions"
        onLabel="Hide Filters"
        offLabel="Show Filters"
        class="p-button-outlined"
        id="filter-toggle"
      ></ToggleButton>
    </div>

    <div
      id="filter-container"
      class="p-mb-5 p-grid p-jc-center"
      :class="{ hide: !showFilterOptions }"
    >
      <span class="p-input-icon-left p-col-12 p-md-5">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="searchTerm"
          placeholder="Search"
        ></InputText>
      </span>
      <span class="p-col-10 p-md-5">
        <MultiSelect
          v-model="selectedTags"
          :options="tags"
          optionLabel="name"
          display="chip"
          :filter="true"
          id="tags-filter"
          placeholder="Filter Tags"
        ></MultiSelect>
      </span>
      <span class="p-col-2" id="favorite-filter-container">
        <ToggleButton
          v-model="favoriteFilter"
          onIcon="pi pi-heart"
          offIcon="pi pi-heart"
        ></ToggleButton>
      </span>
    </div>
    <RecipeList
      v-if="recipes.length"
      :recipes="recipes"
      :favoriteFilter="favoriteFilter"
      :searchTerm="searchTerm"
      :tagsFilter="selectedTags"
    ></RecipeList>
    <div v-else id="skeleton-container" class="p-grid">
      <div v-for="index in 10" :key="index" class="p-col-6 p-xl-4">
        <Skeleton shape="square"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import getRecipes from "@/composables/getRecipes";
import RecipeList from "@/components/RecipeList";
import { ref, watch } from "vue";
import getTags from "@/composables/getTags";
import { projectAuth } from "@/firebase/config";
import router from "@/router";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: { RecipeList },
  setup() {
    const store = useStore();


    let showFilterOptions = ref(store.getters.showFilterOptions);
    watch(showFilterOptions, () => store.dispatch("setShowFilterOptions", showFilterOptions.value))

    const { recipes, error, load } = getRecipes();
    load();

    let favoriteFilter = ref(store.getters.favoriteFilter);
    watch(favoriteFilter, () => store.dispatch("setFavoriteFilter", favoriteFilter.value))

    let searchTerm = ref(store.getters.searchTerm);
    watch(searchTerm, () => store.dispatch("setSearchTerm", searchTerm.value))


    let selectedTags = ref(store.getters.tags);
    watch(selectedTags, () => store.dispatch("setTags", selectedTags.value))

    let tags = getTags;
    projectAuth.onAuthStateChanged((user) => {
      if (!user) {
        router.push({ name: "Login" });
      }
    });

    return {
      recipes,
      error,
      favoriteFilter,
      searchTerm,
      tags,
      selectedTags,
      showFilterOptions,
    };
  },
};
</script>

<style scoped>
.p-skeleton {
  max-width: 25rem;
  height: 20rem !important;
  margin-bottom: 5rem;
}

.hide {
  display: none;
}

.p-inputtext {
  width: 100% !important;
}

.p-input-icon-left > i:first-of-type {
  left: 1.5rem !important;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

#tags-filter {
  width: 100%;
}

#favorite-filter-container {
  display: flex;
  align-items: center;
}

#filter-toggle-container {
  display: flex;
  justify-content: center;
}

#filter-toggle {
  margin: 0 auto 2rem auto;
}
</style>
