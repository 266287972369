import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import FullCalendar from "primevue/fullcalendar";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Card from "primevue/card";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import ToggleButton from "primevue/togglebutton";
import Slider from "primevue/slider";
import InputMask from "primevue/inputmask";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import FileUpload from "primevue/fileupload";
import Tag from "primevue/tag";
import ConfirmPopup from "primevue/confirmpopup";
import Skeleton from "primevue/skeleton";
import Dialog from "primevue/dialog";
import "swiper/swiper-bundle.css";
import InputNumber from "primevue/inputnumber";
import TabMenu from "primevue/tabmenu";
import ScrollTo from "primevue/scrolltop";

createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(store)
  .use(ToastService)
  .use(ConfirmationService)
  .component("Dialog", Dialog)
  .component("FullCalendar", FullCalendar)
  .component("Toast", Toast)
  .component("Button", Button)
  .component("Card", Card)
  .component("Textarea", Textarea)
  .component("InputText", InputText)
  .component("ToggleButton", ToggleButton)
  .component("Slider", Slider)
  .component("InputMask", InputMask)
  .component("MultiSelect", MultiSelect)
  .component("ProgressSpinner", ProgressSpinner)
  .component("FileUpload", FileUpload)
  .component("Tag", Tag)
  .component("ConfirmPopup", ConfirmPopup)
  .component("Skeleton", Skeleton)
  .component("InputNumber", InputNumber)
  .component("TabMenu", TabMenu)
  .component("ScrollTop", ScrollTo)
  .mount("#app");
