<template>
  <Card>
    <template #title>
      Login
    </template>
    <template #content>
      <div class="p-fluid  p-formgrid p-grid">
        <div class="p-field p-col-12">
          <span class="p-float-label">
            <InputText id="email" type="email" v-model="email" />
            <label for="email">Email</label>
          </span>
        </div>

        <div class="p-field p-col-12">
          <span class="p-float-label">
            <InputText id="password" type="password" v-model="password" />
            <label for="password">Password</label>
          </span>
        </div>

        <div class="p-field p-col-12">
          <span id="error">{{ error }}</span>
        </div>
      </div>
    </template>
    <template #footer>
      <Button @click="handleLogin" label="Login" />
    </template>
  </Card>
</template>

<script>
import { ref } from "vue";
import useLogin from "@/composables/useLogin";
import router from "@/router";

export default {
  name: "Login",
  setup() {
    const email = ref("");
    const password = ref("");

    const { error, login } = useLogin();

    const handleLogin = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        await router.push({ name: "Home" });
      }
    };

    return { email, password, handleLogin, error };
  }
};
</script>

<style scoped>
#error {
  color: red;
}
</style>
