<template>
  <Card class="overview-recipe-card">
    <template #header>
      <router-link :to="{ name: 'RecipeDetails', params: { id: recipe.id } }">
        <img
          v-if="recipe.images.length"
          alt="recipe image"
          :src="recipe.images[0]"
          class="recipe-image"
        />
        <img
          v-else
          alt="recipe image"
          src="https://cdn.pixabay.com/photo/2014/12/11/02/55/cereals-563796_960_720.jpg"
          class="recipe-image"
        />
      </router-link>
    </template>

    <template #title>
      <router-link :to="{ name: 'RecipeDetails', params: { id: recipe.id } }">
        <Tag
          v-if="recipe['is-favorite']"
          icon="pi pi-heart"
          class="p-mr-2 favorite"
          severity="success"
          value="Favorite"
          rounded
        ></Tag>
        {{ recipe.title }}
      </router-link>
    </template>

    <template #content>
      <Tag
        v-for="tag in recipe.tags.slice(0, 3)"
        :key="tag"
        class="p-mr-2"
        :value="tag"
        severity="info"
        rounded
      ></Tag>
      <Tag
        v-if="recipe.tags.length > 3"
        class="p-mr-2"
        value=". . ."
        severity="info"
        rounded
      ></Tag>
    </template>

    <template #footer>
      <Button @click="cook" icon="pi pi-book" label="Cook" />
    </template>
  </Card>
</template>

<script>
import router from "@/router";

export default {
  name: "RecipeSingle",
  props: { recipe: Object },
  setup(props) {
    const cook = () => {
      router.push( {name: 'Cook', params: { id: props.recipe.id }} )
    };

    return { cook }
  }
};
</script>

<style scoped>
.p-card {
  max-width: 25rem;
}
.recipe-image {
  width: 100%;
  height: 10rem;
  object-fit: cover;
}
</style>

<style>
.overview-recipe-card .p-card-title {
  word-break: break-word;
}
</style>
