<template>
  <Card>
    <template #title>
      <div class="p-pl-4 p-pr-4">
        <Skeleton height="1.5rem"></Skeleton>
      </div>
    </template>
    <template #content>
      <div class="custom-skeleton p-pt-4 p-pr-4 p-pl-4">
        <div class="p-d-flex p-mb-5">
          <Skeleton height="7rem" class="p-mr-5"></Skeleton>
          <Skeleton height="7rem"></Skeleton>
        </div>
        <Skeleton v-for="index in 4" :key="index" height="2rem" class="p-mb-3"></Skeleton>
        <Skeleton v-for="index in 2" :key="index" height="7rem" class="p-mb-3"></Skeleton>

      </div>
    </template>
    <template #footer>
      <div class="p-d-flex p-mt-3 skeleton-footer">
        <Skeleton width="4rem" height="2rem"></Skeleton>
        <Skeleton width="4rem" height="2rem"></Skeleton>
      </div>
    </template>
  </Card>
</template>

<script>
export default {
name: "RecipeSkeleton"
}
</script>

<style scoped>
.skeleton-footer .p-skeleton{
  margin-right: 0.5rem;
}
</style>