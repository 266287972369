<template>
  <Card>
    <template #title>
      Create Recipe
    </template>
    <template #content>
      <div class="p-fluid  p-formgrid p-grid">
        <div class="p-field p-col-12">
          <RecipeCreateUpload
            :images="images"
            @imagesUploaded="imagesUploaded"
            @imageDeleted="deleteImage"
          ></RecipeCreateUpload>
        </div>
      </div>

      <div class="p-fluid  p-formgrid p-grid">
        <div class="p-field p-col-10">
          <span class="p-float-label">
            <InputText id="title" type="text" v-model="title" />
            <label for="title">Title</label>
          </span>
        </div>

        <div class="p-field p-col-2" id="favorite-button-container">
          <ToggleButton
            v-model="isFavorite"
            onIcon="pi pi-heart"
            offIcon="pi pi-heart"
          />
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label for="servings">Servings: {{ servings }}</label>
          <Slider v-model="servings" :min="1" :max="12" />
        </div>

        <div class="p-field p-col-12 p-md-6">
          <span class="p-float-label">
            <InputMask
              v-model="time"
              mask="99:99"
              slotChar="hh:mm"
              id="time-to-cook"
            />
            <label for="time-to-cook">Time to cook</label>
          </span>
        </div>

        <RecipeCreateTags @updateTags="updateTags"></RecipeCreateTags>

        <div class="p-field p-col-12">
          <span class="p-float-label">
            <Textarea
              v-model="additionalInfo"
              :autoResize="true"
              rows="2"
              cols="30"
              id="additional-info"
            />
            <label for="additional-info">Additional Info</label>
          </span>
        </div>

        <div class="p-field p-col-12 section-end">
          <span class="p-float-label">
            <InputText id="source" type="text" v-model="source" />
            <label for="source">Source</label>
          </span>
        </div>

        <RecipeCreateIngredients
          @updateIngredients="updateIngredients"
        ></RecipeCreateIngredients>

        <div class="p-field p-col-12">
          <span class="p-float-label">
            <Textarea
              v-model="stepsRaw"
              :autoResize="true"
              rows="5"
              cols="30"
              id="steps"
            />
            <label for="steps">Steps (empty line for new section)</label>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <Button @click="submitRecipe" label="Submit" icon="pi pi-check" />
      <Button
        @click="cancel"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-secondary"
        id="cancel-button"
      />
    </template>
  </Card>
  <Toast></Toast>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import RecipeCreateIngredients from "@/components/RecipeCreateIngredients";
import { projectFirestore } from "@/firebase/config";
import router from "@/router";
import { useToast } from "primevue/usetoast";
import RecipeCreateTags from "@/components/RecipeCreateTags";
import RecipeCreateUpload from "@/components/RecipeCreateUpload";
import firebase from "firebase/app";

export default {
  name: "RecipeCreate",
  components: { RecipeCreateUpload, RecipeCreateTags, RecipeCreateIngredients },
  setup() {
    const toast = useToast();
    let title = ref("");
    let isFavorite = ref(false);
    let servings = ref(1);
    let time = ref("");
    let additionalInfo = ref("");
    let source = ref("");

    let stepsRaw = ref([]);
    let ingredients = ref([]);
    let tags = ref([]);
    let images = ref([]);

    let steps = computed(() => stepsRaw.value.split(/\n\s*\n/));

    const imagesUploaded = urls => {
      toast.add({
        severity: "success",
        summary: "Successfully uploaded",
        detail: "Your images have been uploaded",
        life: 3000
      });
      images.value = urls;
    };

    const deleteImage = image => {
      let index = images.value.indexOf(image);
      if (index > -1) {
        images.value.splice(index, 1);
      } else {
        console.log("NO IMAGE TO DELETE FOUND...");
      }
    };

    const updateTags = newTags => {
      tags.value = newTags;
    };

    const updateIngredients = newIngredients => {
      ingredients.value = newIngredients;
    };

    const submitRecipe = () => {
      if (title.value === "") {
        toast.add({
          severity: "error",
          summary: "Missing Title",
          detail: "Please add a title",
          life: 3000
        });
        return;
      }
      if (!(tags.value.length > 0)) {
        toast.add({
          severity: "error",
          summary: "Missing Tags",
          detail: "Please add at least one tag",
          life: 3000
        });
        return;
      }
      if (!(ingredients.value.length > 0)) {
        toast.add({
          severity: "error",
          summary: "Missing Ingredients",
          detail: "Please add at least one ingredient",
          life: 3000
        });
        return;
      }
      if (!(stepsRaw.value.length > 0)) {
        toast.add({
          severity: "error",
          summary: "Missing Steps",
          detail: "Please add at least one step",
          life: 3000
        });
        return;
      }

      let recipe = {
        images: images.value,
        title: title.value.trim(),
        "is-favorite": isFavorite.value,
        servings: servings.value,
        time: time.value,
        "additional-info": additionalInfo.value,
        source: source.value,
        steps: steps.value,
        tags: tags.value,
        ingredients: ingredients.value,
        "created-at": firebase.firestore.FieldValue.serverTimestamp()
      };

      projectFirestore.collection("Recipes").add(recipe);

      router.push({ name: "Home" });
    };

    const cancel = () => {
      router.push({ name: "Home" });
    };

    return {
      images,
      title,
      isFavorite,
      servings,
      time,
      additionalInfo,
      source,
      stepsRaw,
      ingredients,
      tags,
      toast,
      imagesUploaded,
      deleteImage,
      submitRecipe,
      cancel,
      updateTags,
      updateIngredients
    };
  }
};
</script>

<style scoped>
.p-card {
  margin: auto;
  max-width: 50rem;
}

.p-field {
  margin-top: 1rem;
}

.section-end {
  margin-bottom: 3rem;
}

#favorite-button-container {
  display: flex;
  align-items: center;
}

#cancel-button {
  margin-left: 0.5rem;
}
</style>
