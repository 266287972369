<template>
  <FullCalendar :events="meals" :options="options" id="calendar" />
</template>

<script>
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ref } from "vue";
import getMeals from "@/composables/getMeals";

export default {
  name: "MealPlannerWidget",
  setup() {
    const { meals, error, load } = getMeals();
    load();

    let options = ref({
      plugins: [dayGridPlugin],
      initialDate: new Date().toISOString().substring(0, 10),
      headerToolbar: false,
      firstDay: 1,
      initialView: "dayGridWeek",
      editable: false,
      eventDurationEditable: false,
      droppable: false
    });

    return {
      options,
      meals,
      error
    };
  }
};
</script>

<style scoped>
#edit-meal-content {
  width: 100%;
}
</style>

<style>
#calendar {
  margin-right: auto;
  margin-left: auto;
}

.fc-toolbar-title {
  color: var(--text-color);
}
</style>
