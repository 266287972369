import { ref } from "vue";
import { projectFirestore } from "@/firebase/config";

const getRecipe = id => {
  const recipe = ref(null);
  const error = ref(null);

  const load = async () => {
    try {
      const res = await projectFirestore
        .collection("Recipes")
        .doc(id)
        .get();
      if (!res.exists) {
        throw new Error("Non existant recipe");
      }
      recipe.value = { ...res.data(), id: res.id };
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  return {
    recipe: recipe,
    error,
    load
  };
};

export default getRecipe;
