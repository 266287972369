<template>
  <div id="external-events" class="p-grid p-mt-5">
    <span id="search-container" class="p-input-icon-left p-col-12 p-mb-5">
      <i class="pi pi-search" />
      <InputText
        type="text"
        v-model="searchTerm"
        placeholder="Search"
      ></InputText>
    </span>
    <div
      v-for="recipe in filteredRecipes"
      :key="recipe"
      class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event p-col-12 p-mb-3"
    >
      <div class="fc-event-main">{{ recipe.title }}</div>
    </div>
  </div>
</template>

<script>
import getRecipes from "@/composables/getRecipes";
import { computed } from "@vue/reactivity";
import { ref } from "vue";

export default {
  name: "MealPlannerDraggables",
  setup() {
    const { recipes, error, load } = getRecipes();
    load();

    let searchTerm = ref("");

    let filteredRecipes = computed(() => {
      let recipesToReturn = [];
      for (let recipe in recipes.value) {
        let shouldAdd = true;
        if (searchTerm.value !== "") {
          if (
            !recipes.value[recipe].title
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase())
          ) {
            shouldAdd = false;
          }
        }
        if (shouldAdd) {
          recipesToReturn.push(recipes.value[recipe]);
        }
      }
      recipesToReturn.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      return recipesToReturn;
    });

    return { recipes, error, filteredRecipes, searchTerm };
  }
};
</script>

<style scoped>
#external-events {
  width: 90%;
  margin: 0 auto;
}

#search-container {
  padding: 0;
}

.p-inputtext {
  width: 100% !important;
}

.p-input-icon-left > i:first-of-type {
  left: 1rem !important;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

.fc-h-event {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color);
}

.fc-event-main {
  color: var(--primary-color-text);
}
</style>
