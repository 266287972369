import { createStore } from "vuex";

const store = createStore({
    state: {
        showFilterOptions: false,
        favoriteFilter: false,
        searchTerm: "",
        tags: []
    },

    mutations: {
        SET_SHOW_FILTER_OPTIONS(state, showFilterOptions) {
            state.showFilterOptions = showFilterOptions;
        },
        SET_FAVORITE_FILTER(state, favoriteFilterState) {
            state.favoriteFilter = favoriteFilterState;
        },
        SET_SEARCHTERM(state, searchTerm) {
            state.searchTerm = searchTerm;
        },
        SET_TAGS(state, tags) {
            state.tags = tags;
        }
    },

    actions: {
        setShowFilterOptions({ commit }, showFilterOptions) {
            commit('SET_SHOW_FILTER_OPTIONS', showFilterOptions);
        },
        setFavoriteFilter({ commit }, favoriteFilter) {
            commit('SET_FAVORITE_FILTER', favoriteFilter);
        },
        setSearchTerm({ commit }, searchTerm) {
            commit('SET_SEARCHTERM', searchTerm);
        },
        setTags({ commit }, tags) {
            commit('SET_TAGS', tags);
        },
    },
    getters: {
        showFilterOptions: state => {
            return state.showFilterOptions;
        },
        favoriteFilter: state => {
            return state.favoriteFilter;
        },
        searchTerm: state => {
            return state.searchTerm;
        },
        tags: state => {
            return state.tags;
        },
    }
});

export default store;
