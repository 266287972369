import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCPRZtAf5kFufryUytyUpJcDTHoJU9S-xs",
  authDomain: "risotto-cookbook.firebaseapp.com",
  projectId: "risotto-cookbook",
  storageBucket: "risotto-cookbook.appspot.com",
  messagingSenderId: "30759863469",
  appId: "1:30759863469:web:107f01a274d3ce793d7224"
};

firebase.initializeApp(firebaseConfig);
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    if (err.code === "failed-precondition") {
      console.log("This app can only be open in one tab");
    } else if (err.code === "unimplemented") {
      console.log("Browser not supported");
    }
  });

export { projectFirestore, projectAuth };
