import { ref } from "@vue/reactivity";

let tags = ref([
  { name: "Appetizer" },
  { name: "Asian" },
  { name: "Baked Good" },
  { name: "Breakfast" },
  { name: "Chicken" },
  { name: "Dessert" },
  { name: "Dinner" },
  { name: "Healthy" },
  { name: "Kekse" },
  { name: "Lunch" },
  { name: "Meat" },
  { name: "NinaOnly" },
  { name: "Pasta" },
  { name: "Römertopf" },
  { name: "Sauces" },
  { name: "Sides" },
  { name: "Snacks" },
  { name: "Soups" },
  { name: "Spices" },
  { name: "Spreads" },
  { name: "Süß" },
  { name: "Umami" }
]);

export default tags;
