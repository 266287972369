<template>
  <div class="p-field p-col-12">
    <span class="p-float-label">
      <Textarea
        v-model="ingredientsText"
        :autoResize="true"
        rows="5"
        cols="30"
        id="ingredients"
      />
      <label for="ingredients">Ingredients</label>
    </span>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  name: "RecipeCreateIngredients",
  emits: ["updateIngredients"],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    let ingredient = ref("");
    let ingredientsText = ref("");
    let ingredients = ref([]);

    watch(ingredientsText, () => {
      ingredients.value = ingredientsText.value
        .replace(/\r\n/g, "\n")
        .split("\n")
        .map(ingredient => ingredient.trim());

      for (ingredient in ingredients.value) {
        if (isNaN(ingredients.value[ingredient].charAt(0))) {
          ingredients.value[ingredient] = "1 " + ingredients.value[ingredient];
        }
      }
      emit("updateIngredients", ingredients.value);
    });

    return {
      ingredient,
      ingredientsText
    };
  }
};
</script>

<style scoped></style>
