import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import RecipeDetails from "@/views/RecipeDetails";
import RecipeCreate from "@/views/RecipeCreate";
import MealPlanner from "@/views/MealPlanner";
import Cook from "@/views/Cook";
import MealPlannerWidget from "@/views/MealPlannerWidget";
import Login from "@/views/Login";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/recipes/:id",
    name: "RecipeDetails",
    component: RecipeDetails
  },{
    path: "/cook/:id",
    name: "Cook",
    component: Cook
  },
  {
    path: "/create/recipe",
    name: "RecipeCreate",
    component: RecipeCreate
  },
  {
    path: "/meal-planner",
    name: "MealPlanner",
    component: MealPlanner
  },
  {
    path: "/meal-planner/widget",
    name: "MealPlannerWidget",
    component: MealPlannerWidget
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
