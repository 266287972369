<template>
  <FullCalendar :events="meals" :options="options" id="calendar" />

  <Dialog
    :header="editMealTitle"
    v-model:visible="displayModal"
    :style="{ width: '20rem' }"
    :modal="true"
    @hide="editMeal"
  >
    <Textarea
      v-model="editMealContent"
      :autoResize="true"
      rows="5"
      id="edit-meal-content"
    />

    <template #footer>
      <Button @click="cook" icon="pi pi-book" label="Cook" />
      <Button
        label="Delete"
        icon="pi pi-trash"
        class="p-button-outlined p-button-danger"
        @click="deleteEditMeal"
      />
    </template>
  </Dialog>

  <MealPlannerDraggables></MealPlannerDraggables>
</template>

<script>
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { onMounted, ref } from "vue";
import MealPlannerDraggables from "@/components/MealPlannerDraggables";
import getMeals from "@/composables/getMeals";
import { projectFirestore } from "@/firebase/config";
import router from "@/router";
import getRecipes from "@/composables/getRecipes";

export default {
  name: "MealPlanner",
  components: { MealPlannerDraggables },
  setup() {
    const { meals, error, load: loadMeals } = getMeals();
    loadMeals();

    const { recipes, error: recipeError, load: loadRecipes } = getRecipes();
    loadRecipes();

    let options = ref({
      plugins: [dayGridPlugin, interactionPlugin],
      initialDate: new Date().toISOString().substring(0, 10),
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,dayGridWeek"
      },
      firstDay: 1,
      editable: true,
      eventDurationEditable: false,
      droppable: true,
      contentHeight: "auto",
      drop: info => {
        addNewMeal(info);
      },
      eventDrop: info => {
        updateMeal(info);
      },
      eventReceive: info => {
        //Remove original empty event, the "real" one is loaded in eventDrop
        info.event.remove();
      },
      eventClick: info => {
        editMealToDelete.value = info.event;
        editMealTitle.value = info.event.title;
        editMealId.value = info.event.id;
        editMealContent.value = info.event.extendedProps.content;
        displayModal.value = true;
      }
    });

    const addNewMeal = info => {
      let ids = meals.value.map(meal => parseInt(meal.id.replace(/^\D+/g, "")));
      let sortedMealsIds = ids.sort((a, b) => a - b);
      let newMealId = sortedMealsIds[sortedMealsIds.length - 1] + 1;
      if (isNaN(newMealId)) {
        newMealId = 1;
      }
      newMealId = "Meal" + newMealId;

      let newMeal = {
        id: newMealId,
        title: info.draggedEl.innerText,
        start: info.dateStr
      };
      projectFirestore
        .collection("Meals")
        .doc(newMealId)
        .set(newMeal);
      loadMeals();
    };

    const updateMeal = info => {
      let newMeal = {
        id: info.oldEvent.id,
        title: info.event.title,
        start: info.event.startStr
      };
      projectFirestore
        .collection("Meals")
        .doc(info.oldEvent.id)
        .update(newMeal);
    };

    let editMealToDelete = ref();
    let displayModal = ref(false);
    let editMealTitle = ref("");
    let editMealContent = ref("");
    let editMealId = ref(null);

    const editMeal = () => {
      if (editMealContent.value) {
        projectFirestore
          .collection("Meals")
          .doc(editMealId.value)
          .update({ content: editMealContent.value });

        editMealToDelete.value.setExtendedProp(
          "content",
          editMealContent.value
        );
      }
    };

    const deleteEditMeal = () => {
      projectFirestore
        .collection("Meals")
        .doc(editMealId.value)
        .delete();

      for (let i = 0; i < meals.value.length; i++) {
        let meal = meals.value[i];
        if (meal.id === editMealId.value) {
          editMealToDelete.value.remove();
        }
      }

      editMealId.value = null;
      editMealContent.value = null;
      editMealTitle.value = null;
      editMealToDelete.value = null;
      displayModal.value = false;
    };

    const cook = () => {
      for (let recipe in recipes.value) {
        if (recipes.value[recipe].title === editMealTitle.value) {
          router.push({
            name: "Cook",
            params: { id: recipes.value[recipe].id }
          });
        }
      }
    };

    onMounted(() => {
      const containerEl = document.getElementById("external-events");
      new Draggable(containerEl, {
        itemSelector: ".fc-event",
        longPressDelay: 200,
        eventData: function(eventEl) {
          return {
            title: eventEl.innerText
          };
        }
      });
    });

    return {
      options,
      meals,
      error,
      recipeError,
      displayModal,
      editMealTitle,
      editMealContent,
      editMealId,
      editMeal,
      deleteEditMeal,
      cook
    };
  }
};
</script>

<style scoped>
#edit-meal-content {
  width: 100%;
}
</style>

<style>
#calendar {
  margin-right: auto;
  margin-left: auto;
}

.fc-toolbar-title {
  color: var(--text-color);
}

</style>
