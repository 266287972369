<template>
  <div class="recipe-list p-grid">
    <div
      v-for="recipe in filteredRecipes"
      :key="recipe.id"
      class="p-col-6 p-xl-4"
    >
      <RecipeSingle :recipe="recipe" class="recipe"></RecipeSingle>
    </div>
  </div>
</template>

<script>
import RecipeSingle from "@/components/RecipeSingle";
import { computed } from "@vue/reactivity";

export default {
  name: "RecipeList",
  components: { RecipeSingle },
  props: {
    recipes: Array,
    favoriteFilter: Boolean,
    searchTerm: String,
    tagsFilter: Array
  },
  setup(props) {
    let filteredRecipes = computed(() => {
      let recipesToReturn = [];
      for (let recipe in props.recipes) {
        let shouldAdd = true;

        if (props.recipes[recipe].title[0] === " ") {
          shouldAdd = false;
        }

        if (props.favoriteFilter) {
          if (!props.recipes[recipe]["is-favorite"]) {
            shouldAdd = false;
          }
        }

        if (props.searchTerm !== "") {
          if (
            !Object.values(props.recipes[recipe])
              .toString()
              .toLocaleLowerCase()
              .includes(props.searchTerm.toLowerCase())
          ) {
            shouldAdd = false;
          }
        }

        if (props.tagsFilter !== null && props.tagsFilter.length > 0) {
          for (let tag in props.tagsFilter) {
            if (
              !props.recipes[recipe].tags.includes(props.tagsFilter[tag].name)
            ) {
              shouldAdd = false;
            }
          }
        }

        if (shouldAdd) {
          recipesToReturn.push(props.recipes[recipe]);
        }
      }

      recipesToReturn.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))

      return recipesToReturn;
    });


    return { filteredRecipes };
  }
};
</script>

<style scoped>
.recipe {
  margin-bottom: 5rem;
}
</style>
