import { ref } from "@vue/reactivity";
import { projectFirestore } from "@/firebase/config";

const getMeals = () => {
  const meals = ref([]);
  const error = ref();

  const load = async () => {
    try {
      const res = await projectFirestore.collection("Meals").get();
      meals.value = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  return { meals: meals, error, load };
};

export default getMeals;
